import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
import { Navigation, Pagination } from "swiper";
import Link from "next/link";

export default function CategoriesCarousel({ categories }) {
  return (
    <div className="relative">
      <Swiper
        slidesPerView={1}
        spaceBetween={16}
        navigation={true}
        pagination={false}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 30,
            allowTouchMove: false,
          },
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper h-[130px]"
        style={{
          marginLeft: "45px",
          marginRight: "45px",
          position: "unset",
        }}
      >
        {categories?.map((category) => (
          <SwiperSlide key={category.id}>
            <Link href={`/catalog/all?parent_id=${category.id}`} passHref>
              <div className="hover:cursor-pointer">
                <div className="relative mx-auto bg-alice-blue hover:bg-fiery-safety-orange w-[80px] h-[80px] rounded-full">
                  {category?.thumbnails && (
                    <Image
                      src={category.thumbnails.small_url}
                      alt={category.name}
                      layout="fill"
                    />
                  )}
                </div>
                <h4 className="mt-2 text-center text-sm">{category.name}</h4>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
