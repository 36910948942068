const ITEM_UUID = "uuid";

export function setDraftUUID(uuid: string | null) {
  localStorage.setItem(ITEM_UUID, uuid);
}

export function clearDraftUUID() {
  localStorage.removeItem(ITEM_UUID);
}

export function getDraftUUID(): string | null {
  if (!window) return null;
  return localStorage.getItem(ITEM_UUID);
}

export function removeDraftUUID() {
  localStorage.removeItem(ITEM_UUID);
}
